<template>
    <div>
        <posts-component />
    </div>
</template>

<script>
import PostsComponent from '@/components/admin/posts/postsList'
export default {
    name: "AdminPosts",
    components: { PostsComponent },
    data: () => ({

    })
};
</script>
